import React from 'react'
import styled from 'styled-components'

import { Link } from 'gatsby'
import { Navbar, Nav } from 'react-bootstrap'

import wolfSVG from '../../images/wolf.svg'

import './style.css'

const Logo = styled(wolfSVG)`
  width: 50px;
  height: 50px;
  margin-right: 4px;
  margin-top: -10px;
`

const Navigation = ({ siteTitle }, ...props) => (
<Navbar collapseOnSelect expand="lg" bg="tundra" variant="dark">
<Navbar.Brand>
<Logo />
<h1 className="fancy d-inline-block"><Link to='/'>{siteTitle}</Link></h1>
</Navbar.Brand>

  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="ml-auto" >
      <Link className='fancy' to="/">Home</Link> 
      <Link className='fancy' to="/blog">Blog</Link>
      <Link className='fancy' to="/contact">Contact</Link>
    </Nav>
  </Navbar.Collapse>
</Navbar>
)

export default Navigation
