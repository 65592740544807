import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import Header from '../Header/Header'
import Main from '../Main/Main'
import Footer from '../Footer/Footer'

import { Tundra as theme } from '../../themes/Tundra'
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './layout.css'
import { ThemeProvider } from 'styled-components'

const MainLayout = styled.div`
  min-height: 100vh;
`
const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
        <Header siteTitle={data.site.siteMetadata.title} />
          <MainLayout>
            <Main children={children} />
          </MainLayout>
          <Footer />
        </>
      )}
    />
  </ThemeProvider>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
