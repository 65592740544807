import React from 'react'
import styled from 'styled-components'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { Alert, Form, Button } from 'react-bootstrap'

import husky from './husky.png'

const FormWrapper = styled.div`
  width: 90%;
  max-width: 500px;
  background: white;
  padding: 20px;
  border: solid #69828c;
  border-radius: 10px;
`
const FormLabel = styled(Form.Label)`
  ${props => props.theme.defaults.primaryTextUpper};
`
const StyledButton = styled(Button)`
  ${props => props.theme.defaults.secondaryTextUpper};
`
const StyledTitle = styled.h1`
  ${props => props.theme.defaults.primaryTextUpper};
  font-size: 30px;
`
const StyledText = styled.p`
  ${props => props.theme.defaults.secondaryText};
  line-height: 96%;
`


class MailChimp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      status: ''
    }
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const status = await addToMailchimp(this.state.email)
    this.setState({ status });
  }

  render() {
    return (
      <FormWrapper>
        <StyledTitle>Get notified when Tundra Run is ready to launch!</StyledTitle>
        <img alt='A beautiful husky facing left' src={husky}></img>

        <StyledText>We're still working out the rough edges in the game, but we'd love to send you an email when we are doing play test events and when the game is ready for purchase. Sign up to get the latest news!
        </StyledText>

        <Form onSubmit={this.handleSubmit}>

        { this.state.status && this.state.status.result === 'success' ? 
          <Alert variant='success'>
          { this.state.status.msg}
          </Alert> : null
        }

        <Form.Group controlId="formBasicEmail">
          <FormLabel>Email Address</FormLabel>
          <Form.Control 
            type="email" 
            placeholder="name@example.com" 
            onChange={(e) => this.setState({ email: e.target.value })}
            required={true}
            />

        </Form.Group>
        <StyledButton variant="primary" type="submit">
          Sign Up
        </StyledButton>
        </Form>
      </FormWrapper>
    )
  }
}

export default MailChimp