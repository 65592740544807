import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Navigation from '../Navigation/Navigation'

const HeaderWrapper = styled.header`
  ${props => props.theme.defaults.header};
`
const Header = ({ siteTitle }) => (
<HeaderWrapper>
    <Navigation siteTitle={siteTitle}/>
 </HeaderWrapper>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
