import React from 'react'
import styled from 'styled-components'
import { SocialTwitterCircular } from 'styled-icons/typicons/SocialTwitterCircular'
import { Twitch } from 'styled-icons/boxicons-logos/Twitch'

const FooterWrapper = styled.footer`
  font-family: 'Athiti', sans-serif;
  background: #131719;
  color: white;
  text-align: center;
  padding-top: 1.45rem;
  padding-bottom: 1.45rem;
  width: 100%;
  a {
    text-decoration: none;
    color: white;
  }
`
const StyledTwitter = styled(SocialTwitterCircular)`
  width: 40px;
  height: 40px;
  margin-left: 10px; 
  color: #1da1f2;
`

const StyledTwitch = styled(Twitch)`
  width: 40px;
  height: 40px;
  color: #6441a5;
`

const Footer = (props) => (
  <FooterWrapper>
    Created by<a href="https://www.rokkincat.com/">RokkinCat</a>, © {new Date().getFullYear()}

    <a href="https://www.twitter.com/tundrarun/" target="_blank" rel="noopener noreferrer"> 
        <StyledTwitter />
    </a>

    <a href="https://www.twitch.tv/tundrarungame" target="_blank" rel="noopener noreferrer"> 
        <StyledTwitch />
    </a>

  </FooterWrapper>
)

export default Footer
