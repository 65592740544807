import React from 'react'
import styled from 'styled-components'

import Layout from '../components/Layout/layout'
import SEO from '../components/SEO/seo'
import MailChimp from '../components/MailChimp/MailChimp'

const ContactLayout = styled.main`
  display: grid;
  grid-template-columns: auto;
  grid-gap: 10px;
  justify-items: center;
`

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <ContactLayout>
        <MailChimp />
    </ContactLayout>
  </Layout>
)

export default ContactPage