const theme = {
  colors: {
    primary: {
      dark: '#325a73',
    },
  },
}

const defaults = {
  header: {
    marginBottom: '1.45rem',
  },
  primaryText: {
    fontFamily: `'Permanent Marker', cursive`,
  },
  secondaryText: {
    fontFamily: `'Athiti', sans-serif`,
  },
  primaryTextUpper: {
    fontFamily: `'Permanent Marker', cursive`,
    textTransform: 'uppercase',
  },
  secondaryTextUpper: {
    fontFamily: `'Athiti', sans-serif`,
    textTransform: 'uppercase',
  }

}

const Tundra = {...theme, defaults }
export { Tundra }